import { environment } from 'aimmo-core2/environments/environment';
import { AimmoCorePhase } from 'aimmo-core2/environments/environment.interface';

export function isAimmoCorePhase(): boolean {
  return [
    AimmoCorePhase.local,
    AimmoCorePhase.dev,
    AimmoCorePhase.qa,
    AimmoCorePhase.beta,
    AimmoCorePhase.prod
  ].includes(environment.phase);
}

export function isAimmoCoreAzurePhase(): boolean {
  return [
    AimmoCorePhase.localAzure,
    AimmoCorePhase.devAzure,
    AimmoCorePhase.qaAzure,
    AimmoCorePhase.betaAzure,
    AimmoCorePhase.prodAzure
  ].includes(environment.phase);
}

export function isNotProdPhaseForAimmoCoreAzure(): boolean {
  return [
    AimmoCorePhase.localAzure,
    AimmoCorePhase.devAzure,
    AimmoCorePhase.qaAzure
  ].includes(environment.phase);
}

export function isProdPhaseForAimmoCoreAzure(): boolean {
  return environment.phase === AimmoCorePhase.prodAzure;
}
